export default function initializeSlideshows() {
  const slideshowConfig = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    fade: true,
    speed: 2500,
    pauseOnFocus: false,
    pauseOnHover: false,
    waitForAnimate: false,
    nextArrow: "<button class=\"slick-next\" aria-label=\"Next\" type=\"button\"><img src=\"/images/bloodcenter_microsite/chevron-right.svg\" /></button>",
    prevArrow: "<button class=\"slick-prev\" aria-label=\"Previous\" type=\"button\"><img src=\"/images/bloodcenter_microsite/chevron-left.svg\" /></button>",
  };

  document.querySelectorAll(".js-slideshow-mount").forEach(mount => {
    jQuery(mount).slick(slideshowConfig);
  });
}
