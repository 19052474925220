// Import any needed component render/init files here
import initializeSlideshow from "../../common/js//components/Slideshow/initializeSlideshow.js";

// Initialize components and behaviors after is loaded
document.addEventListener("DOMContentLoaded", () => {
  // Execute objectFit Polyfill
  window.objectFitPolyfill && window.objectFitPolyfill();

  // initialize Slideshows
  initializeSlideshow();
});
